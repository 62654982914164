@tailwind base;
@tailwind components;
@tailwind utilities;

.bg-custom {
    z-index: -1;
    background-image: url("./assets/images/home/background.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
}

.custom-image button {
    opacity: 0;
}

.custom-image div {
    opacity: 0;
}

.custom-image:hover button {
    opacity: 1;
}

.custom-image:hover div {
    opacity: 0.5;
}

@media only screen and (max-width: 768px) {
    .bg-custom {
        background-image: url("./assets/images/home/mobileBackground.webp");
        background-size: 100% auto;
        background-position: top;
    }

    body {
        z-index: -1;
        background-image: url("./assets/images/home/background.webp");
        background-attachment:scroll;
    }
}